var FormStuff = {
  init: function() {
    // kick it off once, in case the radio is already checked when the page loads
    this.applyConditionalRequired();
    this.bindUIActions();
  },

  bindUIActions: function() {
    // when a radio or checkbox changes value, click or otherwise
    $("input[type='radio'], input[type='checkbox']").on("change", this.applyConditionalRequired);
    $('input[data-value-pair]').change(function() { $($(this).data("value-pair")).val($(this).val()) });
  },

  applyConditionalRequired: function() {
    // find each input that may be hidden or not
//    $(".require-if-active").each(function() {
    $("[data-require-pair]").each(function() {
      var el = $(this);
      // find the pairing radio or checkbox
      var pel=$(el.data("require-pair"));
      if (!pel.is(":disabled")) {
        if (pel.is(":checked")) {
          // if its checked, the field should be required
          el.prop("required", true);
          el.prop("disabled", false);
        } else {
          // otherwise it should not
          el.prop("required", false);
          el.prop("disabled", true);
        }
      }
    });
  }

};

FormStuff.init();