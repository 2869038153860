(function ($) {
  "use strict"; // Start of use strict
  var trigger=".trigger-scroll";
  var $trigger=$(trigger);
  var mainNav="#main-nav";
  var $mainNav=$(mainNav);
  // Smooth scrolling using jQuery easing
  $('a'+trigger+'[href*="#"]:not([href="#"])').click(function () {
    // odstranit eventuální předchozí kotvu z adresy
    history.replaceState("", document.title, window.location.pathname + window.location.search);
    if (location.pathname.replace(/^\//, "") == this.pathname.replace(/^\//, "") && location.hostname == this.hostname) {
      var $target = $(this.hash);
      $target = $target.length ? $target : $("[name=" + this.hash.slice(1) + "]");
      if ($target.length) {
        $("html, body").animate(
          {
            scrollTop: $target.offset().top - ($mainNav.length ? $mainNav[0].clientHeight : 0) - 10 // ~90
          },
          1000,
          "easeInOutExpo"
        );
        return false;
      }
    }
  });

  // Closes responsive menu when a scroll trigger link is clicked
  if ($trigger.length) {
    $trigger.click(function () {
      $(".navbar-collapse").collapse("hide");
    });
  };

  if($mainNav.length) {
    // Activate scrollspy to add active class to navbar items on scroll
    $("body").scrollspy({
      target: mainNav,
      offset: 120,
    });
    // Collapse Navbar
    var navbarCollapse = function () {
      if ($mainNav.offset().top > 100) {
        $mainNav.addClass("navbar-shrink");
      } else {
        $mainNav.removeClass("navbar-shrink");
      }
    }
    // Collapse now if page is not at top
    navbarCollapse();
    // Collapse the navbar when page is scrolled
    $(window).scroll(navbarCollapse);
  }
})(jQuery); // End of use strict