function replaceAll(t,s,r) {
  var result=t;
  do {
    t=result;
    result=t.replace(s,r);
  } while (result!=t);
  return result;
}

function removeSpaces(t) {
  return replaceAll(t,' ','');
}

function findLabelForControl(el) {
  var labels=document.getElementsByTagName('label');
  for(var i=0;i<labels.length;i++) {
    if (labels[i].htmlFor==el.id)
      return labels[i];
  }
}

function alertWithLabel(msg,theControl) {
  var label=findLabelForControl(theControl);
  var s='';
  if (label) { s=label.textContent+': '};
  alert(s+msg);
}

function checkPsc(theInput) {
  var ok=theInput.value=='';
  var str=removeSpaces(theInput.value);
  ok=ok||str.length==0||str.length==5&&parseInt(str)>0;
  if (ok) {
    if (str.length>0) {theInput.value=str.substring(0,3)+' '+str.substring(3,5)};
  }
  else {
    alert('PS\u010C je \u0161patn\u011B zad\u00E1no!');
    focusEx(theInput);
  };
  return ok;
}


function checkEmail(theInput) {
  var ok=theInput.value=='';
  var re=/^([a-zA-Z0-9_\-])([a-zA-Z0-9_\-\.]*)@(\[((25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])\.){3}|((([a-zA-Z0-9\-]+)\.)+))([a-zA-Z]{2,}|(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])\])$/;
  ok=ok||re.test(theInput.value);
  if (!ok) {
    alert('Neplatn\u00FD e-mail!');
    focusEx(theInput);
  };
  return ok;
}


function checkPhoneCZ(theInput) {
  var re=/^((\+420)?|(420)?)\d{9,9}$/;
  var ok=re.test(theInput.value);
  if (!ok) {
    alert('Neplatn\u00FD telefon!');
    focusEx(theInput);
  };
  return ok;
};


function checkDateCZ(theInput) {
  theInput.value=removeSpaces(theInput.value);
  var re=/(\d{1,2}).(\d{1,2}).(\d{4})/;
  var m=re.exec(theInput.value);
  var ok=m&&m.length!=0;
  if (ok) {
    var intDay=parseInt(m[1]);
    var intMonth=parseInt(m[2]);
    var intYear=parseInt(m[3]);
    var dtmDate=new Date(intYear,intMonth-1,intDay);
    ok=ok&&dtmDate.getFullYear()==intYear;
    ok=ok&&dtmDate.getMonth()==intMonth-1;
    ok=ok&&dtmDate.getDate()==intDay;
  };
  if (!ok) {
    alert('Neplatn\u00E9 datum!');
    focusEx(theInput);
  };
  return ok;
};


function checkDateTimeCZ(theInput) {
  var re=/^ *\d? *\d *. *\d? *\d *.( *\d){4}/;
  var m=re.exec(theInput.value);
  var ok=m&&m.length!=0;
  if (ok) {
    var d=m[0];
    var t=theInput.value.substr(m.index+d.length,theInput.value.length);
    d=removeSpaces(d);
    t=removeSpaces(t);
    re=/(\d{1,2}).(\d{1,2}).(\d{4})/;
    m=re.exec(d);
    ok=m&&m.length!=0;
    if (ok) {
      var intDay=parseInt(m[1]);
      var intMonth=parseInt(m[2]);
      var intYear=parseInt(m[3]);
      var dtmDate=new Date(intYear,intMonth-1,intDay);
      ok=ok&&dtmDate.getFullYear()==intYear;
      ok=ok&&dtmDate.getMonth()==intMonth-1;
      ok=ok&&dtmDate.getDate()==intDay;
    };
    if (ok) {
      re=/^([0-1]?\d|2[0-3])(:[0-5]?\d(:[0-5]?\d)?)?$/
      ok=t==''||re.test(t);
      if (ok) {
        theInput.value=d+' '+t;
      }
      else { alert('Neplatn\u00FD \u010Das!') };
    }
    else { alert('Neplatn\u00E9 datum!') };
  }
  else { alert('Neplatn\u00E9 datum!') };
  if (!ok) {
    focusEx(theInput);
  }
  return ok;
};

function checkInteger(theInput) {
  theInput.value=removeSpaces(theInput.value);
  var ok=parseInt(theInput.value)==theInput.value;
  if (!ok) {
    alertWithLabel('Mus\u00ED b\u00FDt zad\u00E1no \u010D\u00EDslo!',theInput);
    focusEx(theInput);
  };
  return ok;
};


function checkPositiveInteger(theInput) {
  theInput.value=removeSpaces(theInput.value);
  var ok=parseInt(theInput.value)>0;
  if (!ok) {
    alertWithLabel('Mus\u00ED b\u00FDt zad\u00E1no kladn\u00E9 \u010D\u00EDslo!',theInput);
    focusEx(theInput);
  }
  return ok;
}


function checkUpperLimit(theInput,Limit) {
  theInput.value=removeSpaces(theInput.value);
  var ok=parseInt(theInput.value)<=parseInt(Limit);
  if (!ok) {
    alertWithLabel('Mus\u00ED b\u00FDt zad\u00E1no \u010D\u00EDslo men\u0161\u00ED nebo rovno '+Limit+'!',theInput);
    focusEx(theInput);
  }
  return ok;
}


function checkLowerLimit(theInput,Limit) {
  theInput.value=removeSpaces(theInput.value);
  var ok=parseInt(theInput.value)>=parseInt(Limit);
  if (!ok) {
    alertWithLabel('Mus\u00ED b\u00FDt zad\u00E1no \u010D\u00EDslo v\u011Bt\u0161\u00ED nebo rovno '+Limit+'!',theInput);
    focusEx(theInput);
  }
  return ok;
}


function checkInput(theInput) {
  var err=false;
  var typekind=theInput.getAttribute('alt');
  if (typekind) {
    var atypekind=typekind.split('_');
    switch (atypekind[0].charAt(0)) {
      case 'm':
      case 'n':
        var limit=parseInt(atypekind[1]);
        if (atypekind.length>1&&theInput.value.length>limit&&limit>0) { theInput.value=theInput.value.substring(0,limit) };
        var s='ad\u00E1no '+theInput.value.length;
        if (limit>0){s='Max. '+limit+', z'+s+', zb\u00FDv\u00E1 '+(limit-theInput.value.length)} else {s='Z'+s};
        document.getElementById(theInput.name+'_info').innerHTML=s+'.';
      break;
    }
  }
  return !err;
}

function setSubmit(theForm,enable) {
  if (document.all||document.getElementById) {
    for (i=0;i<theForm.length;i++) {
      var theInput=theForm.elements[i];
      if (theInput.type.toLowerCase()=='submit'&&theInput.name.substr(0,3).toLowerCase()!='del') theInput.disabled=!enable;
    }
  }
}

function resetForm(theForm) {
  for (var i=0;i<theForm.length;i++) {
    var theInput=theForm.elements[i];
    var typekind=theInput.getAttribute('alt');
    if (typekind) {
      var atypekind=typekind.split('_');
      switch (typekind.charAt(0)) {
        case 'm':
        case 'n':
          theInput.value=theInput.defaultValue;
          checkInput(theInput);
          break;
      }
    }
  }
}

function onChangeInput(theInput) {
  var err=false;
  var typekind=theInput.getAttribute('alt');
  if (!typekind&&(theInput.getAttribute('class')=='required')) typekind='t+';
  if (typekind) {
    var atypekind=typekind.split('_');
    var required=atypekind[0].charAt(atypekind[0].length-1)=='+';
    if (required) { atypekind[0]=atypekind[0].substr(0,atypekind[0].length-1) };
    if (theInput.disabled||theInput.style.display=='none') required=false;
    switch (typekind.charAt(0)) {
      case 'd':
        if (atypekind[0].length>1) {
          switch (atypekind[0].charAt(1)) {
            case 't':
              err=required&&theInput.value=='';
              err=err||theInput.value!=''&&!checkDateTimeCZ(theInput);
          };
        }
        else {
          theInput.value=removeSpaces(theInput.value);
          err=required&&theInput.value=='';
          err=err||theInput.value!=''&&!checkDateCZ(theInput);
        };
        break;
      case 'i':
        theInput.value=removeSpaces(theInput.value);
        if (theInput.value!='') {
          err=err||!checkInteger(theInput);
          if (!err&&atypekind[0].length>1) {
            switch (atypekind[0].charAt(1)) {
              case 'p':
                err=err||!checkPositiveInteger(theInput);
                break;
            };
          };
          err=err||atypekind.length>1&&!checkUpperLimit(theInput,atypekind[1]);
          err=err||atypekind.length>2&&!checkLowerLimit(theInput,atypekind[2]);
        };
        err=err||required&&theInput.value=='';
        break;
      case 'm':
      case 'n':
        break;
      case 'p':
      case 't':
        if (atypekind[0].length>1) {
          switch (atypekind[0].charAt(1)) {
            case 'e':
              theInput.value=removeSpaces(theInput.value);
              err=err||!(theInput.value==''||checkEmail(theInput));
              break;
            case 'p':
              theInput.value=removeSpaces(theInput.value);
              err=err||!(theInput.value==''||checkPhoneCZ(theInput));
              break;
          };
        };
        err=err||required&&theInput.value=='';
        break;
      case 's':
      default:
        err=err||required&&theInput.value=='';
    };
  };
  return !err;
}

function validateForm(theForm) {
  var err=false;
  for (var i=0;i<theForm.length;i++) {
    err=err||!onChangeInput(theForm.elements[i]);
  };
  if (err) alert('Nejsou vypln\u011Bny v\u0161echny povinn\u00E9 \u00FAdaje nebo jsou zad\u00E1ny nespr\u00E1vn\u011B!');
  return !err;
}


function refreshItems(arrItems) {
  var strHead=arrItems[0][0];
  var strFoot=arrItems[0][1];
  var elementId=arrItems[0][2];
  var calcId=arrItems[0][3];
  var minVar=arrItems[0][4];
  var arrPrototype=arrItems[1];

  var strOut=new String('');
  var intCounter=0;
  var strBuf=new String('');

  for (var i=2;i<arrItems.length;i++) { if (arrItems[i][0]) intCounter++ };
  var blnCanRemove=(intCounter>minVar)&&(minVar>=0);

  intCounter=0;
  for (var i=2;i<arrItems.length;i++) {
    if (arrItems[i][0]) {
      strOut=strOut.concat('<tr>');
      for (var j=2;j<arrPrototype.length-1;j++) {
        strOut=strOut.concat('<td>'+arrPrototype[j].replace('value=""','value="'+arrItems[i][j]+'"')+'</td>');
      }
      strBuf='<td>'+arrPrototype[j].replace('*',i.toString())+'</td>';
      if (blnCanRemove) strBuf=strBuf.replace(' disabled>','>');
      strOut=strOut.concat(strBuf+'</tr>');
      intCounter++;
    }
  }
  document.getElementById(elementId).innerHTML=strHead+strOut+strFoot;
  if (calcId.length>0) document.getElementById(calcId).innerHTML=intCounter;
}

function saveValues(theForm,arrItems) {
  var i=1;
  var j=2;
  do { i++ } while(i<arrItems.length&&!arrItems[i][0]);
  for (var c=0;c<theForm.length&&i<arrItems.length;c++) {
    var theInput=theForm.elements[c];
    if (theInput.name.charAt(0)=='x') {
      arrItems[i][j]=theInput.value;
      j++;
      if (j==arrItems[2].length) {
        j=2;
        do { i++ } while(i<arrItems.length&&!arrItems[i][0]);
      }
    }
  }
}

function reloadItems(arrItems) {
  var j=0;
  for (var i=2;i<arrItems.length;i++) arrItems[i][0]=arrItems[i][1];
  refreshItems(arrItems);
}

function deleteItem(theForm,arrItems,itemId) {
  saveValues(theForm,arrItems);
  arrItems[itemId][0]=false;
  refreshItems(arrItems);
  validateForm(theForm);
}

function addItem(theForm,arrItems) {
  saveValues(theForm,arrItems);
  var i=arrItems.length;
  var Len=arrItems[2].length
  arrItems[i]=new Array(Len);

  arrItems[i][0]=true;
  arrItems[i][1]=false;
  for (var j=2;j<Len;j++) arrItems[i][j]='';
  refreshItems(arrItems);
  validateForm(theForm);
}

function focusId(id) {
  var field=document.getElementById(id);
  if (field) {
    field.focus();
    field.select();
  }
}
function focusEx(theInput) {
  setTimeout('focusId("'+theInput.id+'")',10);
}

function CheckAll(f,el) {
	for (var i=0;i<f.elements.length;i++) {
		var e=f.elements[i];
		if (e.name==el) e.checked=f.check_all.checked;
	}
}

function showFileList(filesId,listId) {
	var input=document.getElementById(filesId);
	var ul=document.getElementById(listId);
	while (ul.hasChildNodes()) {
		ul.removeChild(ul.firstChild);
	}
  if (input.files.length>1) {
    for (var i=0;i<input.files.length;i++) {
  		var li=document.createElement("li");
  		li.innerHTML=input.files[i].name;
  		ul.appendChild(li);
  	}
  }
}

function checkMultipleFile(filesId,labelId,labelText) {
	var input=document.getElementById(filesId);
  if (input.multiple) {
    var label=document.getElementById(labelId);
    label.innerHTML=labelText;
  }
  return input.multiple;
}